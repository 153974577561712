export default{
    counter:0,
    addStaff:null,
    updateStaff:null,
    staffs:[],
    columns:null,
    errorMsg:null,
    specializationStaff:null,
    networkStaff:null,
    addContacts:null,
    staffContactList:null,
    staffContactColms:null,
    addAvailability:null,
    availabilityList:null,
    addStaffRole:null,
    roleList:null,
    roleListColms:null,
    addProvider:null,
    providerList:null,
    providerListColms:null,
    roles:null,
    providers:null,
    staffMeta:null,
    addStaffDocument:null,
    staffDocuments:null,
    closeModal:false,
    clearStaffFormValidation:false,
    availabilityDetails:false,
    contactDetails:false,
    documentStaffDetails:null,
   
}