const common = {
  urgent: 'Urgent',
  medium: 'Medium',
  normal: 'Normal',
  reply: 'Reply',
  view: 'View',
  edit: 'Edit',
  delete: 'Delete',
  flag: 'Flag',
  reason: 'Reason',
  removalReason:"Removal Reason",
  comment:"Comment",
  clearFlag:"Clear Flag On ",
  patient:'Patient',
  for:"For"
}


export default common
