const timeLogs = {
  department: "Department",
  category: "Category",
  loggedBy: "Logged By",
  performedBy: "Performed By",
  date: "Date",
  timeAmount: "Time Amount",
  cptCode: "Activity",
  note: "Note",
  activeCode:"Activity",
  addTimeLog:"Add Time Log",
}

export default timeLogs;