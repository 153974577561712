export default {
  timeLogReportList: null,
  timeLogReportColumns:null,
  editAuditTimeLog:null,
  timeLogeMeta:null,
  auditTimePermissions:null,
  timeLogView:null,
  viuewTimeReportModal:null,
  auditTimeLogFilterDates:null,
  
  auditMetaLog:null,
  timelineReport:null,
  
 
}