const careCoordinator = {
    specialization:'Especialización',
    
    coordinatorsModal:{
        careCoordinator:'Care Coordinator',
        addNewCoordinator:'Agregar nueva coordinadora',
        personalInformation:'Personal Information'
    }

  }
  
  
  export default careCoordinator