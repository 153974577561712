export default {
  callPlanned:null,
  communicationTypes:null,
  communicationsList: null,
  patientsList: null,
  patientDetails: null,
  futureAppointments: null,
  newRequests: null,
  addCommunication: null,
  communicationsCount: null,
  searchCommunications: null,
  communicationMeta:null,
  conferenceId:null,
  conversationList:[],
  conversationSend:null,
  communicationsView:null,
  latestmessage:[],
  messagesMeta:null,
  conversationWithPatient:null,
  conversationsList:null,
  callDetails:null,
  showSmsData:null,
  communicationRoute:localStorage.getItem('communicationRoute')
}