export default {
    counter:0,
    errorMsg: null,
    provider: null,
    providerLocation: null,
    providersListAll: null,
    providerLocationList: null,
    editSingleProvider: null,
    updateSingleProvider: null,
    providerMeta:null,
    editProviderLocation:null
}