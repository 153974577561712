export default {
    cptCodes:null,
    cptCodesMeta:null,
    reportDetailList:null,
    bloodPressureColumns:null,
    bloodOxygenColumns:null,
    bloodGlucoseColumns:null,
    bloodPressure:null,
    bloodGlucose:null,
    bloodOxygen:null,
    bloodPressureData:null,
    bloodGlucoseData:null,
    bloodOxygenData:null,
    takeDateTime:[],
    takeLength:[],
    dateWiseSortVitals:[],
    cptCodeFilterDates:null,
    cptTimeline:null,
}