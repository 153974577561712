const thresholds = {
    generalParameters:'General Parameters',
    addNewParameters:'Add New Parameters',
    thresholdModal:{
        generalParameters:'General Parameters',
        generalParametersGroup:'General Parameters Group',
        highLimit:'High Limit',
        lowLimit:'Low Limit'

    }

  } 


export default thresholds