const global = {
  dashboard:'Tablero de mandos',
  day:'Día',
  date:'Fecha',
  validation:'se requiere.',
  validValidation:'Por favor ingrese válida',
  week:'Semana',
  month:'Mes',
  email:'Correo electrónico',
  listView:'Vista de la lista',
  view:'vista',
  yesterday:'Ayer',
  today:'Hoy dia',
  tomorrow:'Mañana',
  callQueue:'Cola de llamadas',
  exportToExcel:'Exportar a Excel',
  firstName: 'Primer nombre',
  middleName:'Segundo nombre',
  lastName: 'Apellido',
  designation: 'Designacion',
  gender: 'Género',
  phoneNo: 'Telefono no',
  specialization: 'Especialización',
  network: 'Red',
  previous: 'Previo',
  next: 'Próximo',
  done: 'Hecho',
  add:'Agregar',
  edit:'Editar',
  delete:'Borrar',
  startTime:'Hora de inicio',
  time:'Hora',
  duration:'Duración',
  chooseStartTime:'Elija la hora de inicio',
  endTime:'Hora de finalización',
  chooseEndTime:'Elija la hora de finalización',
  name:'Nombre',
  document:'Documento',
  type:'Escribe',
  tags:'Etiquetas',
  provider:'Proveedor',
  country:'País',
  state:'Expresar',
  city:'Ciudad',
  zipcode:'Código postal',
  address:'Habla a',
  relation:'Relación',
  status:'Estado',
  filter:'Filtrar',
  taskCount:'Recuento de tareas',
  reports:'Informes',
  reportName:'Reportar nombre',
  startDate:'Fecha de inicio',
  endDate:'Fecha final',
  download:'Descargar',
  save:'Ahorrar',
  clear:'Clara'

}


export default global
