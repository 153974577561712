const global = {
  active:'Active',
  inactive:'Inactive',
  update:'Update',
  cancel: 'Cancel',
  ok: 'Ok',
  validation:'is required.',
  validValidation:'Please enter valid',
  dashboard: 'Dashboard',
  day: 'Day',
  date:'Date',
  listView:'List View',
  view:'view',
  yesterday:'Yesterday',
  today:'Today',
  tomorrow:'Tomorrow',
  week: 'Week',
  month: 'Month',
  firstName: 'First Name',
  middleName:'Middle Name',
  lastName: 'Last Name',
  dateOfBirth:'Date of Birth',
  designation: 'Designation',
  gender: ' Biological Gender',
  email: 'Email',
  phoneNo: 'Phone Number',
  specialization: 'Specialization',
  network: 'Network',
  exportToExcel: 'Export to Excel',
  previous: 'Previous',
  next: 'Next',
  done: 'Done',
  callQueue: 'Call State',
  add:'Add',
  edit:'Edit',
  delete:'Delete',
  restore:'Restore',
  startTime:'Start Time',
  time:'Time',
  duration:'Duration',
  chooseStartTime:'Choose start Time',
  endTime:'End Time',
  chooseEndTime:'Choose End Time',
  name:'Name',
  document:'Document',
  type:'Type',
  tags:'Tags',
  provider:'Provider',
  country:'Country',
  state:'State',
  city:'City',
  zipcode:'Zip Code',
  address:'Address',
  relation:'Relation',
  status:'Status',
  filter:'Filter',
  taskCount:'Task Count',
  reports:'Billing',
  reportName:'Report Name',
  startDate:'Start Date',
  endDate:'End Date',
  download:'Download',
  category:'Category',
  note:'Note',
  save:'Save',
  clear:'Clear',
  send:'Send',
  service:'Service',
  isPrimary:'Is Primary',
  currentPassword:'Current Password',
  newPassword:'New Password',
  confirmPassword:'Confirm Password',
  confirmNewPassword:'Confirm New Password',
  resetPassword:'Reset Password',
  submit:'Submit',
  familyMembers:'Family Members',
  responsiblePersons:'Responsible Persons',
  addFamilyMembers:'Add Family Member',
  updateFamilyMembers: 'Update Family Member',
  addResponsiblePersons:'Add Responsible Person',
  careCoordinators:'Care Coordinators',
  addPhysician:'Add Care Coordinator',
  careTeam:'Health Team',
  addCareTeam:'Add Health Team',
  careCoodinator:'Care Coordinator',
  staff:'Staff',
  startCall:'Start Call',
  placeOfService: 'Place of Service',
  macAddress: 'MAC Address',
  serialNumber: 'Serial Number',
  description:"Description",
  priority:"Priority",
  title:"Title",
  yes:"Yes",
  no:"No",
  vitalAuthorization:"Vitals Authorization",
  messageAuthorization:"Messages Authorization",
}


export default global
