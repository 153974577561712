const appointmentCalendar = {
    appointment:'Appointment',
    appointmentCalendar:'Appointment Calendar',
    newAppointment:'New Appointment',
    fullCalendarView:'Full Calendar View',
    hideCalendarView:'Hide Calendar View',
    physician:'Physician',
    careCoordinator:'Care Coordinator',

    addAppointment:{
        appointmentDetail:'Appointment Detail',
        addAppointment:'Add Appointment',
        patient:'Patient',
        staff:'Staff',
        startDate:'Start Date',
        typeOfVisit:'Type of Visit',
        note:'Note',
        
    }
  }


 


export default appointmentCalendar