export default {
    totalPatientcount: null,
    // activePaitientcount: null,
    // newPatientcount: null,
    // inActivePaitientcount: null,
    // abnormalPaitientcount: null,
    // criticalPaitientcount: null,
    grid:null,
    patientsCondition:null
    
}