const timeLogReport = {
    auditTimeLog:'Audit Time Log',
    filterBy:'Filter By',
    view:'View',
    staff:'Staff',
    patient:'Patient',
    note:'Note'
  }


export default timeLogReport