const sidebar = {
  communications: 'Comunicaciones',
  careCoordinator: 'Coordinadora de cuidados',
  patients: 'Pacientes',
  appointmentCalendar: 'Calendario de citas',
  tasks: 'Tareas',
  reports: 'Informes',
  generalParameters: 'Parametros generales',
  auditTimeLog: 'Registro de tiempo de auditoría',
  administration: 'Administración',
}


export default sidebar
