// import {
//   dateOnlyFormat,
//   globalDateFormat,
//   convertResponse,
//   convertData,
//   timerFromTimestamp,
//   secondsToTime
//   // convertChartResponse,
// } from "@/commonMethods/commonMethod";
export const addReports = (state, data) => {

  state.addReports = data
}
export const screensWeb = (state, data) => {

  state.screensWeb = data
}