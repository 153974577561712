export default {
  patientCareCoordinatorsList:null,
  latestHealthTeam:null,
  deletePatientCareCoordinator:null,
  physiciansList:[],
  latestPhysician:null,
  latestCareCoordinator:null,
  patientCareCoordinatorDetails:null,
  updatePatientCareCoordinator:null,
  staffRecord:[]
}