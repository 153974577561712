export default {
    todayAppointmentState: null,
    //callStatus: null,
    patientsCondition: null,
    specialization: null,
    wellness: null,
    network:null,
   // cptCodeValue:null,
    totalPatientsChartValue:null,
    appointmentChartValue:null,
    timeLineButton:null,
    permissions:null,
    //financialValue:null,
}