const careCoordinator = {
    specialization:'Specialization',

    coordinatorsModal:{
        careCoordinator:'Care Coordinator',
        addNewCoordinator:'Add New Care Coordinator',
        updateCoordinator:'Update Care Coordinator',
        personalInformation:'Personal Information'

    },

    roles:{
        role:'Role',
    }

  }
  
  
  export default careCoordinator