export default{
    errorMsg:null,
    successMsg:null,
    searchAppointmentRecords:null,
    todayDate:null,
    officeTime:null,
    weekName:null,
    calendarDate:null,
    latestAppointment:null,
    vitalData:null,
    vitalSuccessMsg:null,
    vitalList:null,
    vitalEdit:null,
    generalParameterMeta:null,
}