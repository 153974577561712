const thresholds = {
    generalParameters:'Parametros generales',
    addNewParameters:'Agregar nuevos parámetros',
    thresholdModal:{
        generalParameters:'Parametros generales',
        generalParametersGroup:'Grupo de parámetros generales',
        highLimit:'Limite alto',
        lowLimit:'Limite bajo'

    }

  } 


export default thresholds