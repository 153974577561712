const inventory = {
  deviceType: 'Device Type',
  modelNumber: 'Model Number',
  serialNumber: 'Serial Number',
  macAddress: 'MAC Address',
  activeStatus: 'Active/Inactive',
}


export default inventory
