const login = {
    welcome: 'Welcome to Virtare Healthcare',
    login: 'Login',
    message: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    forgotPassword: 'Forgot Password ?',
    loginUser: 'Login',
    ePlaceholder: 'Email',
    psdPlaceholder: 'Password',
    unauthenticated:"Unauthenticated"
    
  }


export default login
