export default{
    errorMsg:null,
    successMsg:null,
    searchAppointmentRecords:null,
    todayDate:null,
    officeTime:null,
    weekName:null,
    calendarDate:null,
    latestAppointment:null,
    showMoreRecords:null,
    appointmentConference:null,
    getStaff:null,
    appointmentDetails:null,
    patientAppointmentsList:null,
    requestCall:null
}