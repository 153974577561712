export default {
  rolesList: null,
  rolePermissions: null,
  addRole: null,
  roleDetails: null,
  deleteRole: null,
  UpdateRole: null,
  editRolesAndPermissions:null, 
  dashboardWidget:null,
  editWidget:null,
  rolesMeta:null,
}
