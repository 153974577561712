export default {
  timeApproval: [],
  timeLogReportColumns:null,
  editAuditTimeLogApproval:null,
  timeLogeMeta:null,
  auditTimePermissions:null,
  timeLogView:null,
  viuewTimeReportModal:null,
  auditTimeLogFilterDates:null,
  
  auditMetaLog:null,
  timelineReport:null,
  
 
}