const login = {
  welcome:'Bienvenido a Ditstek Care',
  login:'Acceso',
  message:'Lorem Ipsum es simplemente texto ficticio de la impresión y composición tipográfica industria. Lorem Ipsum ha sido el texto de relleno estándar de la industria. desde el siglo XVI, cuando un impresor desconocido tomó una galera de tipos yrevuelto para hacer un tipo de libro de muestras.',
  forgotPassword:'¿Has olvidado tu contraseña?',
  ePlaceholder:'Correo electrónico',
  psdPlaceholder:'Contraseña'
  }


export default login
