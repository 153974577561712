const common = {
  urgent: 'Urgente',
  medium: 'Medio',
  normal: 'Normal',
  reply: 'Respuesta',
  view: 'Vista',
  edit: 'Editar',
  delete: 'Borrar',
  clearFlag:"Borrar Indicador Activado",
  
  for:"Por",
}


export default common
