const globalCodes = {
  category:'Category',
  codeName:'Code Name',
  description:'Description',
  usedCount:'Used Count',
  activeStatus:'Active/Inactive',
}


export default globalCodes
