export default {
  escalationCounter:0,
  escalation:null,
  escalationStaus:null,
  escalationMeta:null,
  addBasicEscalation:null,
  esacalationFlagList:null,
  escalationNotesList:null,
  esacalationCarePlansList:null,
  escalationVitalList:null,
  singleEscalationRecord:null,
  singleEscalationDataArray:null,
  escalationNots:null,
  escalationVitals:null,
  escalationCarePlan:null,
  editEscalationStaff:null,
  editEscalationPatient:null,
  editEscalationDetails:null,
  editSecondStepper:null
}